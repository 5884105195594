@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.loginWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../assests/images/office-with-logo.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.loginCardWrapper{
    display: flex;
    flex-direction: column;
    background: var(--secondary-bg-color);
    border-radius: .5rem;
    /* width: 20%; */
    padding: 1rem;
    font-family: "Montserrat", sans-serif;
}

.loginCardContent{
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* margin-top: 10px;
    margin-bottom: 10px; */
}

.loginCardContent input{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.error {
    color: red;
    margin-top: -10px;
    font-size: small;
    display: flex;
    justify-content: left;
}

input:-webkit-autofill { -webkit-background-clip: text; } /* chrome fix */
input:autofill {box-shadow: unset; }/*  mozilla fix */

.loginCardContent input:focus{
    border-bottom: 1px solid #2c84bc;
}


.btnBlue{
    background: #3d99cc;
    color: white;
    border: none;
    padding: .5rem;
    border-radius: .5rem;
    margin-top: 1rem;
    outline: none;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
    cursor: pointer;
    display: flex;
    justify-content: center;
}
