body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  --bgColor: aliceBlue;
  --secondary-bg-color: white
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.layout-wrapper {
  padding: 2rem;
}

#root {
  height: 100%;
  overflow: auto;
  background-color: var(--bgColor);
}

html{
  font-size: 14px;
  height: 100%
}

.rb-sidebar-expanded a {
  white-space: nowrap;
}

.rb-sidebar-collapsed li ul li {
  border-bottom: 1px solid black !important;;
}

.rb-sidebar-child {
  padding: 0 1rem !important;
}

.rb-sidebar-wrapper ::before {
  font-size: 1.25rem;
}

.rb-sidebar-wrapper li span {
  display: flex;
  align-items: center;
}

.rb-sidebar-wrapper li img {
  /*filter: invert(52%) sepia(86%) saturate(2449%) hue-rotate(159deg) brightness(91%) contrast(101%);*/
  filter: invert(50%) sepia(0%) saturate(1524%) hue-rotate(143deg) brightness(100%) contrast(84%);
}

.p-splitter-panel{
  overflow: auto;
}

.p-datatable-header{
  border-radius: 1rem 1rem 0 0 !important;
}

.p-paginator-bottom{
  border-radius: 0 0 1rem 1rem !important;
}

.p-sidebar-content{
  padding: 0.5rem !important;
}

.p-sidebar-header{
  justify-content: space-between !important;
}

.p-float-label input:-webkit-autofill ~ label{
  top: -10px !important;
}

.fieldsetNoPadding .p-fieldset-content{
  padding: 0 !important;
}

.p-grid{
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}


.p-datatable-wrapper{
  overflow: auto !important;
}

.as-sb .p-button{
  width: 50%;
}

.zoom {
  transition: transform .2s;
}

.zoom:hover {
  transform: scale(1.5);
}

.vehicle-without-gps:not(:hover){
  background-color: var(--no-gps-row) !important;

}

.p-speeddial{
  right: 0 !important;
  /*margin-top: 0rem !important;*/
  padding-right: 3.5rem;
}

.p-speeddial-button{
  width: 3rem !important;
  height: 3rem !important ;
}

li.p-speeddial-item {
  /*opacity: 1!important;*/
  /*transition: transform 200ms cubic-bezier(0.4,0,0.2,1) 0ms !important;*/
  transition: unset;
}

.speeddial-operations{
  height: 3rem;
  position: relative;
}

.speeddial-right{
  padding-right: 2rem !important;
}

.speeddial-left{

}

div.p-speeddial.p-component.p-speeddial-linear.p-speeddial-direction-left.speeddial-right:not(.p-speeddial-opened) > ul {
  display: none !important;
}

#status_dropdown_input div {
  margin-left: 0 !important;
}

.button-border-left-radius {
  border-top-left-radius: 1rem
}

.speeddial-left-important-overwrite {
  right: auto !important;
}

#password .p-icon-field.p-icon-field-right {
  width: 100%
}

.layout-dark {
  --bgColor: #171e29;
  --secondary-bg-color: #1f2937;
  --textColor: rgba(255, 255, 255, 0.87);
  --taskColumnColor: #171E29;
  --greenColor: lime;
  --pinkColor: #ff000091;
  --redColor: red;
  --orangeColor: orange;
  --blueColor: rgb(17, 177, 255);
  --tableHover: #385b7e;
  --30Days: white;
  --no-gps-row: rgba(241, 120, 120, 0.3);
  --firmBoxColor: #171e29;
  --firmBoxHoverColor: #385b7e
}

.layout-white {
  --bgColor: aliceBlue;
  --secondary-bg-color: white;
  --textColor: #6c757d;
  --taskColumnColor: #F7F8F9;
  --greenColor: green;
  --redColor: red;
  --orangeColor: orange;
  --blueColor: #2196f3;
  --tableHover: #e9ecef;
  --30Days: gray;
  --no-gps-row: #b0373729;
  --firmBoxColor: aliceBlue;
  --firmBoxHoverColor: #b4d9fd
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  background: var(--tableHover)
}

.multiselect-flex .p-multiselect-label.p-multiselect-items-label {
  display: flex;
  gap: 1rem
}

.no-master-checkbox .p-datatable-thead tr:nth-child(2)>th div.p-checkbox{
  display: none
}
