.right-sidebar {
    height: calc(100% - 50px);
    width: 0px;
    position: absolute;
    z-index: 1;
    top: 50px;
    right: 0;
    background-color: white;
    overflow-x: hidden;
    transition: .5s;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.bubble
{
    position: relative;
    width: 250px;
    height: 120px;
    padding: 0px;
    background: white;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.6);
    -moz-box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.6);
    box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.6);
    border-radius: 5px;
    /*right: 20px*/
    left: -198px;
    top: 50px
}

.bubble:after{
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 20px 20px;
    border-color: white transparent;
    display: block;
    width: 0;
    z-index: 1;
    top: -20px;
    right: 20px;
}

/*.dropdown-content button{*/
/*    margin: 0 !important;*/
/*    width: 100%;*/
/*    font-size: 18px;*/
/*}*/

.dropdown-content div {
    margin: 0 !important;
    width: 100%;
    font-size: 20px;
    float: none;
    text-decoration: none;
    display: block;
    text-align: center;
    color: #666;
    padding: 5px;
}

.dropdown-content div:hover {
    background-color: #ddd;
    cursor: pointer;
}

/*.dropdown-content buton {*/
/*    float: none;*/
/*    text-decoration: none;*/
/*    display: block;*/
/*    text-align: center;*/
/*    color: #666;*/
/*}*/

/*.dropdown-content p:hover {*/
/*    background-color: #ddd;*/
/*    cursor: pointer;*/
/*}*/