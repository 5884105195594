/* react-bar */
.rb-sidebar-collapsed ul li:hover, .rb-sidebar-expanded ul li:hover div span {
    color: var(--blue-500) !important;
}

/* If there is a submenu - set the color of the children li when the parent is hovered */
.rb-sidebar-expanded ul li:hover ul li div span {
    color: black !important;
}

/* If there is a submenu - set the color of the children li when hovered */
.rb-sidebar-expanded ul li:hover ul li:hover div span {
    color: var(--blue-500) !important;
}

/* If a child is selected, set it's color to blue */
.rb-sidebar-expanded .rb-sidebar-item-expanded ul .rb-sidebar-active div span {
    color: var(--blue-500) !important;
}

.rb-sidebar-item-expanded {
    color: #212121 !important;
    /*background: var(--primary-700) !important*/
}

li.rb-sidebar-active {
    color: white !important;
    /*background: var(--primary-700) !important;*/
}

.rb-sidebar-wrapper {
    background: linear-gradient(180deg,#f3f4f9 0,#d7dbe8) !important;
}

ul li.rb-sidebar-active {
    color: var(--blue-500) !important;
}

.rb-sidebar-collapsed .rb-no-border {
    padding: 0 !important;
}

.rb-sidebar-wrapper, .rb-sidebar-wrapper span {
    font-size: 1rem;
}

.rb-sidebar-wrapper span {
    padding: 0.75rem !important;
}

.rb-sidebar-wrapper.rb-sidebar-collapsed > ul > li:first-child {
    padding: 0.355rem 0 !important;
}

.rb-sidebar-wrapper.rb-sidebar-expanded > ul > li:first-child {
    padding: 0.31rem 0 !important;
}

.rb-sidebar-wrapper ul li {
    padding: 0.22rem;
    color: black
}

.rb-sidebar-wrapper.rb-sidebar-collapsed {
    overflow: auto !important;
}

.rb-sidebar-wrapper.rb-sidebar-expanded {
    overflow: auto !important;
}

main.rb-sidebar-content-collapsed, main.rb-sidebar-content-expanded {
    margin-top: 0 !important;
    transform: translate(0, 50px);
}


/* DARK MODE FROM HERE */
/* react-bar */
.layout-dark .rb-sidebar-collapsed ul li:hover, .layout-dark .rb-sidebar-expanded ul li:hover div span {
    color: var(--primary-200) !important;
}

/* If there is a submenu - set the color of the children li when the parent is hovered */
.layout-dark .rb-sidebar-expanded ul li:hover ul li div span {
    color: var(--textColor) !important;
}

/* If there is a submenu - set the color of the children li when hovered */
.layout-dark .rb-sidebar-expanded ul li:hover ul li:hover div span {
    color: var(--primary-200) !important;
}

/* If a child is selected, set it's color to black */
.layout-dark .rb-sidebar-expanded .rb-sidebar-item-expanded ul .rb-sidebar-active div span {
    color: #1f2937 !important;
}

.layout-dark .rb-sidebar-expanded ul li {
    color: var(--textColor)
}

.layout-dark .rb-sidebar-item-expanded {
    color: var(--secondary-bg-color) !important;
    background: var(--primary-700) !important
}

.layout-dark li.rb-sidebar-active {
    color: var(--textColor) !important;
    background: var(--primary-700) !important;
}

.layout-dark .rb-sidebar-wrapper {
    background: var(--secondary-bg-color) !important;
}

.layout-dark ul li.rb-sidebar-active {
    color: var(--secondary-bg-color) !important;
}

.layout-dark .rb-sidebar-collapsed ul li {
    color: var(--textColor) !important;
}

.rb-sidebar-collapsed .rb-no-border {
    padding: 0 !important;
}

.rb-sidebar-wrapper, .rb-sidebar-wrapper span {
    font-size: 14px;
}

.rb-sidebar-wrapper span {
    padding: 0.75rem !important;
}

.rb-sidebar-wrapper.rb-sidebar-collapsed > ul > li:first-child {
    padding: 0.355rem 0 !important;
}

.rb-sidebar-wrapper.rb-sidebar-expanded > ul > li:first-child {
    padding: 0.31rem 0 !important;
}

.rb-sidebar-wrapper ul li {
    padding: 0.22rem;
}

.rb-sidebar-wrapper.rb-sidebar-collapsed {
    overflow: auto !important;
}

.rb-sidebar-wrapper.rb-sidebar-expanded {
    overflow: auto !important;
}

main.rb-sidebar-content-collapsed, main.rb-sidebar-content-expanded {
    margin-top: 0 !important;
    transform: translate(0, 50px);
}
